







































































































































import { FormHelper, Toast } from '@/mixins'
import { Component, Mixins } from 'vue-property-decorator'
import { UserModule } from '@/store/modules'
import { GM } from '@/api'

enum PunishmentType {
  'Ban' = 0,
  'Mute' = 1
}

@Component
export default class AddPunishmentModal extends Mixins(FormHelper, Toast) {
  @UserModule.State currentUser

  characterName = ''
  dateStop: Nullable<Date> = null
  isOpen = false
  permanentBan = false
  proofs: string[] = ['']
  punishmentType = null
  punishmentTypes = [
    { label: 'Ban', value: PunishmentType.Ban },
    { label: 'Mute', value: PunishmentType.Mute }
  ]
  reason = ''

  async mounted() {
    this.$root.$on('openAddPunishmentModal', () => {
      this.isOpen = true
    })
  }

  resetForm() {
    this.characterName = ''
    this.dateStop = null
    this.permanentBan = false
    this.proofs = ['']
    this.punishmentType = null
    this.reason = ''
  }

  get areFieldsFilled() {
    return this.characterName && this.dateStop && this.proofs[0].length > 0 && this.punishmentType && this.reason
  }

  async addProof() {
    try {
      const { characterName, currentUser, dateStop, punishmentType, proofs, reason } = this
      const punishment = {
        characterName,
        dateStop: this.permanentBan ? "2038-01-01 01:00" : dateStop,
        judgeAccountId: currentUser.id,
        judgeAccountName: currentUser.username,
        // @ts-ignore
        punishmentType: punishmentType.value,
        proofs,
        reason
      }

      await GM.addPunishment(punishment)

      this.showSuccessToast({
        // @ts-ignore
        title: `Successfully ${punishmentType.value ? 'muted' : 'banned'} ${characterName}`,
        message: `An entry has been added to Punishment Log`
      })
      this.isOpen = false
    } catch(e) {
      this.showErrorToast({
        title: 'An error occurred'
      })
      console.error('Error when trying to add proof', e)
    }
  }
}
